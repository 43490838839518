import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const DedicatedtPage = () => (
  <div>
    <SEO 
        title="Dedicated Hosting Packages 24–7–365 Client Support" 
        description="Each Linux dedicated servers hosting package includes a 24x7 technical support for all the pre–installed applications coming with it."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-2">
          <div className="text-center mx-auto">
              <p className="section-title"><span className="block text-indigo-600 xl:inline">Dedicated</span> Hosting Packages 24–7–365 Client Support<span role="img" aria-label="emoji">✨ </span></p>
              <p className="section-after-title">
              Each Linux dedicated servers hosting package includes a 24x7 technical support for all the pre–installed applications coming with it.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title text-indigo-800">
              Client Support Channels
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/ticket-response-time.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A 1–Hour Response Guarantee</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        All your support requests will be responded to in maximum one hour after the trouble ticket or email is submitted. 
                        If your case requires additional assistance from a sysadmin, we will forward the trouble ticket or the email to 
                        the person on duty, right away then leave a comment to notify you that more time will be required for finding 
                        the optimal solution. Furthermore if you take advantage of our Administration Services package, all of the 
                        server management tasks included there will be carried out whenever you choose.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/quality-support-service.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Premium Support Service</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Our help desk support staff will be available to you 24/7/365 should you need assistance working with the Web Control Panel 
                        or with any of the apps that we have preinstalled for you on the dedicated web hosting server. What is more if you need 
                        assistance handling all the regular admin tasks on your dedicated hosting server, you can order our Administration 
                        Services package, that includes data backup hard disk space, weekly OS updates, installation and troubleshooting 
                        procedures, server monitoring and restarting procedures, etc.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/help-center.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">An Easy–to–Navigate Help Section</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Every Linux dedicated servers hosting package is equipped with our charge–free Web Control Panel, which boasts an exhaustive 
                        knowledgebase and a set of step–by–step video tutorials. Whenever you require assistance while working with the Web Control 
                        Panel, just push the Help or Videos buttons in the top right corner.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default DedicatedtPage
